// 給最新衛教與口碑見證頁面使用的簡易分頁 hooks

import { NumberParam, useQueryParams, withDefault } from 'use-query-params'

const usePaginationQuery = () => {
  const [paginationQuery, setPaginationQuery] = useQueryParams({
    page: withDefault(NumberParam, 1)
  })

  const handlePaginationReset = () => {
    setPaginationQuery(
      {
        page: 1
      },
      'pushIn'
    )
  }

  const handlePageChange = (page: number) => {
    setPaginationQuery({ page })
  }

  return {
    paginationQuery,
    handlePaginationReset,
    handlePageChange
  }
}

export default usePaginationQuery
