import { LoadingOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import { any, equals, isNil, map } from 'ramda'
import React, { FC } from 'react'
import styled from 'styled-components'
import { StringParam } from 'use-query-params'

import NewsCoverImg from '../../assets/static/news_cover.png'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import { News } from '../../components/Cards'
import { RadioFilter } from '../../components/Filter'
import { GridItem, GridLayout } from '../../components/Grid'
import { ThemeProvider } from '../../components/Layout'
import LoadMore from '../../components/LoadMore'
import PageContent from '../../components/PageContent'
import SearchBar from '../../components/SearchBar'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { viewport } from '../../constants/viewport'
import { NewsCategory, NewsListQuery, NewsListQueryVariables } from '../../types/types'
import useFormQuery from '../../utils/hooks/useFormQuery'
import usePaginationQuery from '../../utils/hooks/usePaginationQuery'

const StyledPageContent = styled(PageContent)`
  margin-bottom: 84px;
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const newsFilter = ['ALL', ...Object.values(NewsCategory)] as const

function isNewsCategory(category: NewsCategory | string): category is NewsCategory {
  return any(equals(category), Object.values(NewsCategory))
}

export const newsListQuery = gql`
  query NewsList($query: NewsQuery, $page: Int, $limit: Int, $sort: String) {
    newsList(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        images
        thumbnailImage
        thumbnailAlt
        title
        description
        publishedDate
        alias
      }
      total
      page
    }
  }
`
const NewsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const ContentWrapper = styled.div`
  font-size: 16px;
  ${NewsWrapper} + ${NewsWrapper} {
    margin-top: ${space.s}px;
  }
`

const TagAndSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${space.l}px;

  @media (max-width: ${viewport.tablet - 1}px) {
    flex-direction: column-reverse;
  }
`

const BreadcrumbBarWrapper = styled.div`
  margin: ${space.l}px 0;
`
const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`

const pageLimit = 100

const formInput = {
  category: StringParam,
  q: StringParam
}

type Props = RouteComponentProps

const NewsList: FC<Props> = () => {
  const { formatMessage } = useIntl()

  const { formQuery, handleFormChange } = useFormQuery(formInput)
  const { paginationQuery, handlePaginationReset } = usePaginationQuery()

  const categoryFilter = formQuery?.category ?? 'ALL'
  const page = paginationQuery?.page
  const searchString = formQuery?.q ?? ''

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'breadcrumb.news' }),
      path: '/news'
    }
  ]

  const { data, loading } = useQuery<NewsListQuery, NewsListQueryVariables>(newsListQuery, {
    variables: {
      query: {
        category: isNewsCategory(categoryFilter) ? [categoryFilter] : undefined,
        q: searchString
      },
      page,
      limit: pageLimit
    }
  })

  const newsData = data?.newsList?.docs ?? []
  const total = data?.newsList?.total ?? 0

  const handleSelect = (value: string) => {
    if (value === 'ALL' || value === NewsCategory.Beauty || value === NewsCategory.Missing || value === NewsCategory.Toothache || value === NewsCategory.Children) {
      handleFormChange({ category: value })
      handlePaginationReset()
    }
  }

  const handleSearch = (value: string) => {
    handleFormChange({ q: value })
    handlePaginationReset()
  }

  return (
    <>
      <StyledPageContent>
        <ThemeProvider>
          <GridLayout templateColumns={['100%', '100%', 'repeat(12, 1fr)']}>
            <GridItem column={['auto', 'auto', '3 / span 8']}>
              <ContentWrapper className='news-content'>
                <BreadcrumbBarWrapper>
                  <BreadcrumbBar routes={routes} />
                </BreadcrumbBarWrapper>
                <StyledContentTitle>{formatMessage({ id: 'pageTitle.news' })}</StyledContentTitle>
                <TagAndSearchContainer>
                  <RadioFilter
                    value={categoryFilter}
                    onSelect={handleSelect}
                    options={map(filterType => ({ label: formatMessage({ id: `news.${filterType}` }), value: filterType }), newsFilter)}
                  />
                  <SearchBar handleSearch={handleSearch} value={searchString} />
                </TagAndSearchContainer>
                {!loading ? (
                  <LoadMore
                    templateColumns={['100%', 'repeat(2, 1fr)']}
                    rowGap={[`${space.xl}px`, `${space.xxl}px`]}
                    columnGap={[`${space.s}px`, `${space.xxl}px`]}
                    defaultItems={10}
                    perClick={10}
                    amount={total}
                    itemList={newsData}
                  >
                    {items => (
                      <News
                        key={items.id}
                        title={items.title}
                        date={moment(items.publishedDate).format('YYYY/MM/DD')}
                        newsId={items.id}
                        alias={items.alias}
                        thumbnailImage={!isNil(items.thumbnailImage) ? items.thumbnailImage : NewsCoverImg}
                        thumbnailAlt={items.thumbnailAlt}
                        description={items.description}
                      />
                    )}
                  </LoadMore>
                ) : (
                  <LoadingWrapper>
                    <LoadingOutlined />
                  </LoadingWrapper>
                )}
              </ContentWrapper>
            </GridItem>
          </GridLayout>
        </ThemeProvider>
      </StyledPageContent>
    </>
  )
}

export default NewsList
