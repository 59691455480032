import { Input } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { LightGrey, Primary } from '../constants/newColor'

const { Search } = Input

interface Props {
  value?: string
  handleSearch: (value: string) => void
}

const SearchBar = (props: Props) => {
  const { value, handleSearch } = props

  const onSearch = (value: string) => {
    handleSearch(value)
  }

  const InputContainer = styled.div`
    display: flex;

    /** 覆寫 antd 預設 style */
    .ant-input {
      background-color: ${LightGrey};
    }
    .ant-input-search {
      border: 1px solid ${Primary};
      border-radius: 5px;
      background-color: ${LightGrey};
      margin: 0.3rem;
    }
    .ant-input-search-icon::before {
      border: none;
    }
  `

  return (
    <InputContainer>
      <Search placeholder='熱門搜尋：矯正、牙套...' onSearch={onSearch} defaultValue={value} allowClear />
    </InputContainer>
  )
}

export default SearchBar
